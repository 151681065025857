import { gql } from "@apollo/client";
import {
  AccountStatus,
  BuildCohortAssignmentRoles_CohortStaffAssignmentFragment,
  CohortAssignmentRole,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  CohortEventInput,
  CohortEventToDraftEvent_CohortEventFragment,
  CohortEventToDraftEvent_CohortStaffAssignmentFragment,
  CohortToDraftEvents_CohortFragment,
  UpdateDraftEventsWithCohortAssignmentRoles_CohortStaffAssignmentFragment,
} from "@generated/graphql";
import {
  IANAtzName,
  STANDARD_TIME_ZONES,
  buildWeekdaysCheckFromRecurrenceRuleString,
  floatingToZonedTimeString,
} from "@utils/dateTime";
import add from "date-fns/add";
import uniq from "lodash/uniq";
import { NEW_EVENT_PREFIX } from "./constants";
import { DraftEvent } from "./types";

const draftEventFragments = {
  teacherAssignedUser: gql`
    fragment DraftEventTeacherAssignedUser_User on User {
      id
      accountStatus
      fullName
    }
  `,
  teachersEventAssignment: gql`
    fragment DraftEventTeachersEventAssignment_CohortStaffAssignment on CohortStaffAssignment {
      cohortSubject
      cohortAssignmentRole
      user {
        id
        accountStatus
        fullName
      }
    }
  `,
};

buildCohortAssignmentRoles.fragments = {
  staffAssignment: gql`
    fragment BuildCohortAssignmentRoles_CohortStaffAssignment on CohortStaffAssignment {
      cohortSubject
      cohortAssignmentRole
      user {
        id
        accountStatus
      }
    }
  `,
};

cohortEventToDraftEvent.fragments = {
  cohortEvent: gql`
    fragment CohortEventToDraftEvent_CohortEvent on CohortEvent {
      id
      startDateTime
      durationMinutes
      timeZone
      cohortSubject
      cohortSubSubject
      recurrenceRule
      teacherAssignedId
      teacherAssignedUser {
        ...DraftEventTeacherAssignedUser_User
      }
    }
    ${draftEventFragments.teacherAssignedUser}
  `,
  staffAssignment: gql`
    fragment CohortEventToDraftEvent_CohortStaffAssignment on CohortStaffAssignment {
      cohortSubject
      ...BuildCohortAssignmentRoles_CohortStaffAssignment
      ...DraftEventTeachersEventAssignment_CohortStaffAssignment
    }
    ${buildCohortAssignmentRoles.fragments.staffAssignment}
    ${draftEventFragments.teachersEventAssignment}
  `,
};

cohortToDraftEvents.fragments = {
  cohort: gql`
    fragment CohortToDraftEvents_Cohort on Cohort {
      id
      events {
        ...CohortEventToDraftEvent_CohortEvent
      }
      staffAssignments {
        ...CohortEventToDraftEvent_CohortStaffAssignment
        ...DraftEventTeachersEventAssignment_CohortStaffAssignment
      }
    }
    ${cohortEventToDraftEvent.fragments.cohortEvent}
    ${cohortEventToDraftEvent.fragments.staffAssignment}
    ${draftEventFragments.teachersEventAssignment}
  `,
};

updateDraftEventsWithCohortAssignmentRoles.fragments = {
  staffAssignment: gql`
    fragment UpdateDraftEventsWithCohortAssignmentRoles_CohortStaffAssignment on CohortStaffAssignment {
      cohortSubject
      ...BuildCohortAssignmentRoles_CohortStaffAssignment
      ...DraftEventTeachersEventAssignment_CohortStaffAssignment
    }
    ${buildCohortAssignmentRoles.fragments.staffAssignment}
    ${draftEventFragments.teachersEventAssignment}
  `,
};

/**
 * Takes in a normal CohortEvent and returns a DraftEvent.
 */
function cohortEventToDraftEvent(
  cohortEvent: CohortEventToDraftEvent_CohortEventFragment,
  staffAssignments: CohortEventToDraftEvent_CohortStaffAssignmentFragment[],
  twelveHour: boolean = true
): DraftEvent {
  const weekdaysCheck = buildWeekdaysCheckFromRecurrenceRuleString(
    cohortEvent.recurrenceRule
  );
  const eventEndDateTime = add(new Date(cohortEvent.startDateTime), {
    minutes: cohortEvent.durationMinutes,
  });

  const startTime = floatingToZonedTimeString(
    new Date(cohortEvent.startDateTime),
    cohortEvent.timeZone,
    twelveHour
  );
  const endTime = floatingToZonedTimeString(
    eventEndDateTime,
    cohortEvent.timeZone,
    twelveHour
  );

  return {
    id: cohortEvent.id,
    dirty: false,
    toRemove: false,
    cohortSubject: cohortEvent.cohortSubject,
    cohortSubSubject:
      cohortEvent.cohortSubSubject || CohortAssignmentSubSubject.General,
    startTime,
    endTime,
    timeZone: cohortEvent.timeZone,
    weekdaysCheck,
    teacherAssignedId: cohortEvent.teacherAssignedId ?? undefined,
    cohortAssignmentRoles: buildCohortAssignmentRoles(
      cohortEvent.cohortSubject,
      staffAssignments
    ),
    teachersEventAssignments: staffAssignments.filter(
      (staffAssignment) =>
        staffAssignment.cohortSubject === cohortEvent.cohortSubject
    ),
    teacherAssignedUser: cohortEvent.teacherAssignedUser ?? undefined,
  };
}

/**
 * Convert a Cohort and its CohortEvents into a list of DraftEvents which is the
 * prop type used by this component.
 */
export function cohortToDraftEvents(
  cohort: CohortToDraftEvents_CohortFragment,
  twelveHour: boolean = true
): DraftEvent[] {
  return (cohort?.events ?? []).map((event) =>
    cohortEventToDraftEvent(event, cohort.staffAssignments, twelveHour)
  );
}

/**
 * When we've finished working with DraftEvents its time to generate the input
 * shape that our mutations will understand. Provide DraftEvents and it'll
 * return an array of inputs, perfect for passing to the mutation.
 * @param draftEvent
 * @returns
 */
export const draftEventsToCohortEventsInput = (
  draftEvent: DraftEvent[]
): CohortEventInput[] => {
  return draftEvent
    .filter(({ toRemove }) => !toRemove) // Remove any events marked for removal.
    .map((draftEvent) => {
      const weekdays: number[] = [];
      draftEvent.weekdaysCheck.forEach((checked, index) => {
        if (checked) weekdays.push(index);
      });

      return {
        id: isNewEventId(draftEvent.id) ? undefined : draftEvent.id,
        toUpdate: draftEvent.dirty,
        cohortSubject: draftEvent.cohortSubject,
        cohortSubSubject: draftEvent.cohortSubSubject,
        startTime: draftEvent.startTime,
        endTime: draftEvent.endTime,
        timeZone: draftEvent.timeZone,
        weekdays,
        teacherAssignedId: draftEvent.teacherAssignedId,
      };
    });
};

/**
 * Loop through your draftEvents in state and pass in the Cohort's staffAssignments
 * and the draftEvents will be updated with the relevant data.
 * @param draftEvents
 * @param staffAssignments
 * @returns
 */
export function updateDraftEventsWithCohortAssignmentRoles(
  draftEvents: DraftEvent[],
  staffAssignments: UpdateDraftEventsWithCohortAssignmentRoles_CohortStaffAssignmentFragment[]
): DraftEvent[] {
  return draftEvents.map((draftEvent) => ({
    ...draftEvent,
    cohortAssignmentRoles: buildCohortAssignmentRoles(
      draftEvent.cohortSubject,
      staffAssignments
    ),
    teachersEventAssignments: staffAssignments.filter(
      (staffAssignment) =>
        staffAssignment.cohortSubject === draftEvent.cohortSubject
    ),
  }));
}

/**
 * Because we want to track when a DraftEvent's Subject is assigned to a teacher
 * (Primary or Substitute) this is a helper function that'll do the work for you.
 * @param draftEventCohortSubject
 * @param staffAssignments
 * @returns
 */
export function buildCohortAssignmentRoles(
  draftEventCohortSubject: CohortAssignmentSubject,
  staffAssignments: BuildCohortAssignmentRoles_CohortStaffAssignmentFragment[]
) {
  return uniq(
    staffAssignments
      .filter(
        (staffAssignment) =>
          staffAssignment.cohortSubject === draftEventCohortSubject &&
          staffAssignment.user.accountStatus !== AccountStatus.Inactive
      )
      .map((staffAssignment) => staffAssignment.cohortAssignmentRole)
  );
}

/**
 * Convenience function will attempt to return the client's IANAtzName if they
 * are within our expected list of time zones so that the UI can match correctly.
 * If our user is in an unrecognized time zone, the provided fallback time zone
 * will be used.
 * @param fallbackTz
 * @returns
 */
export const initializeValidTimeZoneFromClient = (
  fallbackTz: IANAtzName
): IANAtzName => {
  const clientIANAtzName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (STANDARD_TIME_ZONES[clientIANAtzName]) {
    return clientIANAtzName;
  } else {
    return fallbackTz;
  }
};

/**
 * Does what it says. This function should be used to prevent the saving of a
 * Cohort where some classes don't have Primary teachers.
 * @param draftEvents
 * @returns
 */
export const checkAllDraftEventsHaveAPrimaryTeacher = (
  draftEvents: DraftEvent[]
): boolean => {
  // Yes, an empty array returns true. We need to prevent that from happening.
  return draftEvents.length === 0
    ? false
    : draftEvents.every((draftEvent) =>
        draftEvent.cohortAssignmentRoles.includes(
          CohortAssignmentRole.PrimaryTeacher
        )
      );
};

/**
 * Determines if the provided DraftEvent is a new event or not. We do this by
 * simply checking for the presence of a special string in the ID value
 * @param id
 * @returns
 */
export const isNewEventId = (id: string) => {
  return id.includes(NEW_EVENT_PREFIX);
};
