import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  AddCohortModal_EngagementFragment,
  AddCohortMutation,
  AddCohortMutationVariables,
  GradeLevel,
  Language,
} from "@generated/graphql";
import { normalizeToUtcDate } from "@utils/dateTime";
import { fromJust } from "@utils/types";
import { getEngagementStaffMap } from "@utils/withFragments/staffing";
import { DraftEvent } from "components/cohorts/CohortScheduler/types";
import { Modal } from "components/shared";
import { getOrCreateDate } from "components/shared/Calendars/utils";
import { AssignCohortStaffAssignment } from "components/staffAssignments/types";
import noop from "lodash/noop";
import { useState } from "react";
import { ENGAGEMENT_DETAILS_EVENT_LOGS_QUERY_NAME } from "sections/Engagements/constants";
import { draftEventsToCohortEventsInput } from "../../CohortScheduler";
import { getScopedTeachers } from "../helpers";
import { AddEditCohortModalBody } from "./AddEditCohortModalBody";

AddCohortModal.fragments = {
  engagement: gql`
    fragment AddCohortModal_Engagement on Engagement {
      id
      endDate
      timeZone
      startDate
      hourlyTTRate
      videoProvider
      rosterRecords {
        status
        teacherId
        ...GetScopedTeachers_RosterRecord
      }
      organization {
        id
        timeZone
      }
      ...GetEngagementStaffMap_Engagement
    }
    ${getScopedTeachers.fragments.rosterRecords}
    ${getEngagementStaffMap.fragments.engagement}
  `,
};

const ADD_COHORT_QUERY = gql`
  mutation AddCohort($input: AddCohortInput!) {
    addCohort(input: $input) {
      id
    }
  }
`;

type Props = {
  show: boolean;
  refetchQueries: string[];
  engagement: AddCohortModal_EngagementFragment;
  onCancel: () => void;
  onSuccess: () => void;
};

export function AddCohortModal({
  show,
  engagement,
  refetchQueries,
  onCancel,
  onSuccess,
}: Props) {
  const initTTRate = engagement.hourlyTTRate;
  const initEngEnd = getOrCreateDate(engagement.endDate);
  const initEngStart = getOrCreateDate(engagement.startDate);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [name, setName] = useState<string | null | undefined>();
  const [startDate, setStartDate] = useState<Date>(initEngStart);
  const [draftEvents, setDraftEvents] = useState<DraftEvent[]>([]);
  const [language, setLanguage] = useState<Language>(Language.English);
  const [hourlyTTRate, setHourlyTTRate] = useState<number>(initTTRate);
  const [endDate, setEndDate] = useState<Date>(getOrCreateDate(initEngEnd));
  const [meetingRoom, setMeetingRoom] = useState<string | null | undefined>();

  const [instructionLevel, setInstructionLevel] = useState<
    GradeLevel | null | undefined
  >();

  const [staffAssignments, setStaffAssignments] = useState<
    AssignCohortStaffAssignment[]
  >([]);

  const resetInputs = () => {
    setName(null);
    setInstructionLevel(null);
    setDraftEvents([]);
    setMeetingRoom(null);
    setStaffAssignments([]);
    setEndDate(initEngEnd); // Resets to initial engagement end date
    setStartDate(initEngStart); // Resets to initial engagement start date
    setHourlyTTRate(initTTRate);
    setLanguage(Language.English);
  };

  const [addCohort, { loading }] = useMutation<
    AddCohortMutation,
    AddCohortMutationVariables
  >(ADD_COHORT_QUERY, {
    onError: (err: ApolloError) => {
      if (err.message.includes("Unique constraint failed")) {
        console.log(err.message);
        setErrorMsg(
          "The cohort name you specified already exists! Please use a different cohort name."
        );
      } else {
        setErrorMsg(err.message);
      }
    },
    onCompleted: () => {
      resetInputs();
      onSuccess();
    },
    refetchQueries: [
      ...refetchQueries,
      ENGAGEMENT_DETAILS_EVENT_LOGS_QUERY_NAME,
    ],
    onQueryUpdated(observableQuery) {
      observableQuery.refetch();
    },
  });

  const onAddCohort = async () =>
    await addCohort({
      variables: {
        input: {
          name: fromJust(name, "name"),
          engagementId: engagement.id,
          hourlyTTRate: Number.isNaN(hourlyTTRate)
            ? null
            : engagement.hourlyTTRate !== hourlyTTRate
            ? hourlyTTRate
            : undefined,
          startDate: normalizeToUtcDate(startDate).getTime(),
          endDate: normalizeToUtcDate(endDate).getTime(),
          instructionLevel: instructionLevel as GradeLevel,
          language: language || Language.English,
          meetingRoom: meetingRoom,
          newStaffAssignments: staffAssignments.map((sa) => ({
            userId: sa.user.id,
            cohortSubject: sa.cohortSubject,
            cohortAssignmentRole: sa.cohortAssignmentRole,
          })),
          cohortEventsInput: draftEventsToCohortEventsInput(draftEvents),
        },
      },
    });

  return (
    <Modal
      show={show}
      onClose={noop}
      icon={<Modal.Icon icon="cohort" />}
      title="Add Cohort"
      width="xxlarge"
      dataTest="add-cohort-modal"
    >
      <AddEditCohortModalBody
        engagement={engagement}
        name={name}
        endDate={endDate}
        errorMsg={errorMsg}
        language={language}
        loadingQuery={false}
        startDate={startDate}
        loadingMutation={loading}
        meetingRoom={meetingRoom}
        draftEvents={draftEvents}
        hourlyTTRate={hourlyTTRate}
        staffAssignments={staffAssignments}
        instructionLevel={instructionLevel}
        isTTRateInherited={
          engagement.hourlyTTRate === hourlyTTRate || Number.isNaN(hourlyTTRate)
        }
        setName={setName}
        setEndDate={setEndDate}
        setErrorMsg={setErrorMsg}
        setLanguage={setLanguage}
        onSaveCohort={onAddCohort}
        setStartDate={setStartDate}
        setMeetingRoom={setMeetingRoom}
        setDraftEvents={setDraftEvents}
        setHourlyTTRate={setHourlyTTRate}
        setStaffAssignments={setStaffAssignments}
        setInstructionLevel={setInstructionLevel}
        onCancel={() => {
          onCancel();
          resetInputs();
        }}
      />
    </Modal>
  );
}
