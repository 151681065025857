import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import {
  Cohort,
  EditCohortModalMutationMutation,
  EditCohortModalMutationMutationVariables,
  EditCohortModalQueryQuery,
  EditCohortModalQueryQueryVariables,
  GradeLevel,
  Language,
} from "@generated/graphql";
import { normalizeToUtcDate } from "@utils/dateTime";
import { fromJust } from "@utils/types";
import { getEngagementStaffMap } from "@utils/withFragments/staffing";
import {
  cohortToDraftEvents,
  draftEventsToCohortEventsInput,
} from "components/cohorts/CohortScheduler";
import { DraftEvent } from "components/cohorts/CohortScheduler/types";
import { COHORT_DETAILS_PAGE_QUERY_NAME } from "components/cohorts/constants";
import { ErrorBox, LoadingSkeleton, Modal } from "components/shared";
import { getOrCreateDate } from "components/shared/Calendars/utils";
import { HOURLY_TT_PAY } from "components/shared/Payments/constants";
import { AssignCohortTeachers } from "components/staffAssignments/AssignCohortTeachers";
import { AssignCohortStaffAssignment } from "components/staffAssignments/types";
import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { ENGAGEMENT_DETAILS_EVENT_LOGS_QUERY_NAME } from "sections/Engagements/constants";
import { AddEditCohortModalBody } from "./AddEditCohortModalBody";

EditCohortModal.fragments = {
  cohort: gql`
    fragment EditCohortModal_Cohort on Cohort {
      id
      name
      endDate
      language
      startDate
      meetingRoom
      hourlyTTRate
      instructionLevel
      curriculumCourseRequired
      staffAssignments {
        user {
          id
          email
          fullName
          accountRole
        }
        cohortSubject
        cohortAssignmentRole
      }
      engagement {
        id
        endDate
        timeZone
        startDate
        hourlyTTRate
        videoProvider
        rosterRecords {
          status
          teacherId
        }
        organization {
          id
          timeZone
        }
        ...GetEngagementStaffMap_Engagement
      }
      ...CohortToDraftEvents_Cohort
      ...AssignCohortTeachers_Cohort
    }
    ${cohortToDraftEvents.fragments.cohort}
    ${AssignCohortTeachers.fragments.cohort}
    ${getEngagementStaffMap.fragments.engagement}
  `,
};

const EDIT_COHORT_MODAL_QUERY = gql`
  query EditCohortModalQuery($id: ID!) {
    cohort(id: $id) {
      id
      ...EditCohortModal_Cohort
    }
  }
  ${EditCohortModal.fragments.cohort}
`;

const EDIT_COHORT_MUTATION = gql`
  mutation EditCohortModalMutation($input: EditCohortInput!) {
    editCohort(input: $input) {
      id
      ...EditCohortModal_Cohort
    }
  }
  ${EditCohortModal.fragments.cohort}
`;

type Props = {
  show: boolean;
  cohortId: Cohort["id"];
  refetchQueries?: string[];
  closeModal: () => void;
  afterLeave?: () => void;
};

export function EditCohortModal({
  show,
  cohortId,
  refetchQueries = [],
  closeModal,
  afterLeave,
}: Props) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [name, setName] = useState<string | null | undefined>();
  const [draftEvents, setDraftEvents] = useState<DraftEvent[]>([]);
  const [endDate, setEndDate] = useState<Date>(getOrCreateDate(null));
  const [language, setLanguage] = useState<Language>(Language.English);
  const [hourlyTTRate, setHourlyTTRate] = useState<number>(HOURLY_TT_PAY);
  const [startDate, setStartDate] = useState<Date>(getOrCreateDate(null));
  const [allIsInitialized, setAllIsInitialized] = useState<boolean>(false);
  const [meetingRoom, setMeetingRoom] = useState<string | null | undefined>();
  const [instructionLevel, setInstructionLevel] = useState<
    GradeLevel | null | undefined
  >();

  const [curriculumCourseRequired, setCurriculumCourseRequired] =
    useState<boolean>(false);

  const [staffAssignments, setStaffAssignments] = useState<
    AssignCohortStaffAssignment[]
  >([]);

  const {
    data,
    error,
    loading: loadingQuery,
  } = useQuery<EditCohortModalQueryQuery, EditCohortModalQueryQueryVariables>(
    EDIT_COHORT_MODAL_QUERY,
    { variables: { id: cohortId }, fetchPolicy: "network-only" }
  );

  const { cohort } = data || {};

  useEffect(() => {
    if (cohort) {
      setName(cohort.name);
      setMeetingRoom(cohort.meetingRoom);
      setEndDate(getOrCreateDate(cohort.endDate));
      setInstructionLevel(cohort.instructionLevel);
      setStaffAssignments(cohort.staffAssignments);
      setStartDate(getOrCreateDate(cohort.startDate));
      setLanguage(cohort.language || Language.English);
      setDraftEvents(cohortToDraftEvents(cohort, false));
      setCurriculumCourseRequired(cohort.curriculumCourseRequired);
      setHourlyTTRate(cohort.hourlyTTRate ?? cohort.engagement.hourlyTTRate);
      setAllIsInitialized(true);
    }
  }, [cohort, data]);

  const [editCohort, { loading: loadingMutation }] = useMutation<
    EditCohortModalMutationMutation,
    EditCohortModalMutationMutationVariables
  >(EDIT_COHORT_MUTATION, {
    onError: (err: ApolloError) => {
      if (err.message.includes("Unique constraint failed")) {
        console.log(err.message);
        setErrorMsg(
          "The cohort name you specified already exists! Please use a different cohort name."
        );
      } else {
        setErrorMsg(err.message);
      }
    },
    onCompleted: () => closeModal(),
  });

  const onEditCohort = async () =>
    await editCohort({
      variables: {
        input: {
          id: cohort?.id || "",
          name: fromJust(name, "name"),
          startDate: normalizeToUtcDate(startDate).getTime(),
          endDate: normalizeToUtcDate(endDate).getTime(),
          instructionLevel: instructionLevel ?? null,
          language: language || Language.English,
          meetingRoom: meetingRoom,
          curriculumCourseRequired,
          hourlyTTRate: Number.isNaN(hourlyTTRate)
            ? null
            : cohort?.engagement.hourlyTTRate !== hourlyTTRate
            ? hourlyTTRate
            : undefined,
          newStaffAssignments: staffAssignments.map((sa) => ({
            userId: sa.user.id,
            cohortSubject: sa.cohortSubject,
            cohortAssignmentRole: sa.cohortAssignmentRole,
          })),
          cohortEventsInput: draftEventsToCohortEventsInput(draftEvents),
        },
      },
      refetchQueries: [
        ...refetchQueries,
        COHORT_DETAILS_PAGE_QUERY_NAME,
        ENGAGEMENT_DETAILS_EVENT_LOGS_QUERY_NAME,
      ],
    });

  return (
    <Modal
      show={show}
      onClose={noop}
      icon={<Modal.Icon icon="cohort" />}
      title="Edit Cohort"
      width="xxlarge"
      afterLeave={afterLeave}
      dataTest="edit-cohort-modal"
    >
      {loadingQuery ? (
        <LoadingSkeleton />
      ) : error ? (
        <ErrorBox msg={error.message} />
      ) : (
        allIsInitialized &&
        cohort && (
          <AddEditCohortModalBody
            engagement={cohort.engagement}
            name={name}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            setName={setName}
            meetingRoom={meetingRoom}
            setMeetingRoom={setMeetingRoom}
            language={language}
            instructionLevel={instructionLevel}
            isTTRateInherited={
              cohort.engagement.hourlyTTRate === hourlyTTRate ||
              Number.isNaN(hourlyTTRate)
            }
            hourlyTTRate={hourlyTTRate}
            setHourlyTTRate={setHourlyTTRate}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setInstructionLevel={setInstructionLevel}
            setLanguage={setLanguage}
            draftEvents={draftEvents}
            setDraftEvents={setDraftEvents}
            staffAssignments={staffAssignments}
            setStaffAssignments={setStaffAssignments}
            onSaveCohort={onEditCohort}
            loadingQuery={loadingQuery}
            loadingMutation={loadingMutation}
            allIsInitialized={allIsInitialized}
            onCancel={closeModal}
            isAddMode={false}
          />
        )
      )}
    </Modal>
  );
}
