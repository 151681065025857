import { GradeLevel } from "@generated/graphql";
import { gradeLevelOptions } from "@utils/selectMenuOptions";
import { SelectMenu, SelectMenuProps } from "../SelectMenu";

type Props = {
  value: GradeLevel;
  className?: string;
  studentGrade?: boolean;
  selectMenuProps?: Omit<SelectMenuProps, "initialIndex">;
  onSelect: (gradeLevel: GradeLevel) => void;
};

export const GradeLevelSelectMenu = ({
  value,
  className,
  selectMenuProps,
  studentGrade = false,
  onSelect,
}: Props) => (
  <SelectMenu
    required
    {...selectMenuProps}
    className={className}
    options={gradeLevelOptions}
    labelText={studentGrade ? "Student Grade" : "Instruction Level"}
    onSelect={({ gradeLevel }) => onSelect(gradeLevel)}
    initialIndex={gradeLevelOptions.findIndex(
      ({ gradeLevel }) => gradeLevel === value
    )}
  />
);
