import { gql } from "@apollo/client";
import {
  GetScopedTeachers_RosterRecordFragment,
  GradeLevel,
  Language,
  RosterRecordStatus,
} from "@generated/graphql";
import {
  LocalizedWeekday,
  WeekdaysCheck,
  weekdayStrings,
} from "@utils/dateTime";
import { parseId } from "@utils/numbers";
import { DraftEvent } from "../CohortScheduler/types";

export const validateCohortModalData = (
  name: string | null | undefined,
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  instructionLevel: GradeLevel | null | undefined,
  draftEvents: DraftEvent[],
  isManagingMode: boolean,
  allDraftEventsHaveAPrimaryTeacher: boolean
) => {
  const newErrors: string[] = [];
  const newWarnings: string[] = [];
  let errorMsg: string | null = null;

  !name?.trim() && newErrors.push("Cohort name is required");
  !startDate && newErrors.push("Start date is required");
  !endDate && newErrors.push("End date is required");
  (!instructionLevel || instructionLevel === GradeLevel.Unknown) &&
    newErrors.push("Instruction Level is required");
  draftEvents.length === 0 && newErrors.push("A Cohort Event is required");
  if (startDate && endDate && +endDate < +startDate) {
    newErrors.push("End date must be after start date");
  }
  if (
    isManagingMode &&
    draftEvents.length !== 0 &&
    !allDraftEventsHaveAPrimaryTeacher
  ) {
    newWarnings.push(
      "All events should have a Primary Teacher for its subject"
    );
  }
  if (startDate && endDate) {
    errorMsg =
      +endDate < +startDate
        ? "End date cannot come before the start date"
        : errorMsg;
  }
  return { newWarnings, newErrors, errorMsg };
};

getScopedTeachers.fragments = {
  rosterRecords: gql`
    fragment GetScopedTeachers_RosterRecord on RosterRecord {
      status
      teacherId
    }
  `,
};

export function getScopedTeachers(
  rosterRecords: GetScopedTeachers_RosterRecordFragment[]
) {
  return rosterRecords
    .filter((rec) => rec.status === RosterRecordStatus.Confirmed)
    .map((rec) => parseId(rec.teacherId));
}

export const validateCohortExpandModalData = (
  language: Language | null | undefined,
  subject: string | null | undefined,
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  hourlyTTRate: number | null | undefined,
  instructionLevel: GradeLevel | null | undefined,
  weekdays: boolean[] | null | undefined
) => {
  const newErrors: string[] = [];

  !startTime && newErrors.push("Start time is required");
  !endTime && newErrors.push("End time is required");
  !language && newErrors.push("Language is required");
  !subject?.trim() && newErrors.push("Subject is required");
  !hourlyTTRate && newErrors.push("Hourly TT Rate is required");
  !weekdays?.includes(true) && newErrors.push("Weekdays are required");

  if (!instructionLevel || instructionLevel === GradeLevel.Unknown)
    newErrors.push(`Instruction Level is required`);
  if (hourlyTTRate && hourlyTTRate < 1)
    newErrors.push("Hourly TT Rate must be positive");
  if (startTime && endTime && startTime >= endTime)
    newErrors.push(`Start time must be before end time`);

  return newErrors;
};

export const getDayName = (
  index: number,
  localizedWeekdays?: LocalizedWeekday[]
) =>
  localizedWeekdays
    ? localizedWeekdays[index].shortWeekday
    : weekdayStrings[index];

export const buildWeekdaysString = (
  weekdaysCheck: WeekdaysCheck,
  localizedWeekdays?: LocalizedWeekday[]
) => {
  return weekdaysCheck
    .map((checked, index) =>
      checked ? getDayName(index, localizedWeekdays) : ""
    )
    .filter((s) => !!s)
    .join(", ");
};

export const buildWeekdaysStringFromNumbers = (weekdaysNums: number[]) =>
  getWeekdaysBools(weekdaysNums)
    .map((checked, index) => (checked ? getDayName(index) : ""))
    .filter((s) => !!s)
    .join(", ");

export const getWeekdaysBools = (weekdaysNums?: number[]) => {
  const weekdays: WeekdaysCheck = Array(7).fill(false) as WeekdaysCheck;
  if (Array.isArray(weekdaysNums) && weekdaysNums.length > 0) {
    weekdaysNums.forEach((num) => (weekdays[num] = true));
  }
  return weekdays;
};
