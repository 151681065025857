import { LocalizedWeekday } from "@utils/dateTime";
import { getScrollbarStyle } from "@utils/styleStrings";
import clsx from "clsx";
import { DraftEvent } from "../../types";
import { DraftEventsTableRow } from "./components/DraftEventsTableRow";

type Props = {
  draftEvents: DraftEvent[];
  readOnly: boolean;
  editDraftEventId?: DraftEvent["id"];
  localizedWeekdays: LocalizedWeekday[];
  onDeleteDraftEvent: (id: DraftEvent["id"]) => void;
  onEditDraftEvent: (id: DraftEvent["id"]) => void;
  addTeacherToADraftEvent: (
    id: DraftEvent["id"],
    teacherId: DraftEvent["teacherAssignedId"] | null
  ) => void;
};

export const DraftEventsTable = ({
  draftEvents,
  readOnly,
  editDraftEventId,
  localizedWeekdays,
  onDeleteDraftEvent,
  onEditDraftEvent,
  addTeacherToADraftEvent,
}: Props) => {
  return (
    <div
      className={clsx(
        "overflow-x-scroll sm:overflow-x-hidden",
        !readOnly && "rounded-md border",
        getScrollbarStyle("gray"),
        draftEvents.length > 7
          ? `overflow-y-auto max-h-[280px]`
          : "overflow-y-visible"
      )}
    >
      <table
        className={clsx(
          "min-w-full divide-y divide-gray-300 table-auto bg-slate-50 rounded-md"
        )}
      >
        <thead>
          <tr>
            {getHeaders(readOnly).map((header, index) => (
              <th
                key={`${header}-${index}`}
                scope="col"
                className="px-3 py-3 text-left text-xs font-medium text-slate-600 uppercase tracking-wider bg-gray-50"
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {draftEvents.map((draftEvent) => (
            <DraftEventsTableRow
              key={draftEvent.id}
              draftEvent={draftEvent}
              readOnly={readOnly}
              editing={draftEvent.id === editDraftEventId}
              toRemove={draftEvent.toRemove}
              localizedWeekdays={localizedWeekdays}
              onDelete={() => onDeleteDraftEvent(draftEvent.id)}
              onEdit={() => onEditDraftEvent(draftEvent.id)}
              onSelect={addTeacherToADraftEvent}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const getHeaders = (readOnly: boolean) => [
  "Subject",
  "Days",
  "Time",
  "Teacher",
  ...(readOnly ? [] : [""]),
];
