import { gql } from "@apollo/client";
import {
  AccountRole,
  AddUsersInput_UserFragment,
  AssignCohortTeachers_CohortFragment,
} from "@generated/graphql";
import { EngagementStaffMap } from "@utils/withFragments/staffing";
import clsx from "clsx";
import { FieldError } from "components/shared";
import { useState } from "react";
import { AddUsersInput } from "./AddUsersInput";
import { CohortStaffAssignmentTable } from "./CohortStaffAssignmentTable";
import {
  CohortStaffAssignmentSelectAddOptions,
  CommonStaffAssignmentOptions,
} from "./types";
import { isCohortStaffAssignmentOptions } from "./utils";

AssignCohortTeachers.fragments = {
  cohort: gql`
    fragment AssignCohortTeachers_Cohort on Cohort {
      staffAssignments {
        user {
          ...AddUsersInput_User
        }
        cohortSubject
        cohortAssignmentRole
      }
    }
    ${AddUsersInput.fragments.user}
  `,
};

type CohortStaffAssignment =
  AssignCohortTeachers_CohortFragment["staffAssignments"][number];

type Props = {
  title?: string;
  userTerm?: string;
  className?: string;
  includedUserIds?: number[];
  optionsInitialIndex?: number;
  engagementStaffMap: EngagementStaffMap;
  staffAssignments: CohortStaffAssignment[];
  predefinedUsers?: AddUsersInput_UserFragment[];
  staffAssignmentOptions: CohortStaffAssignmentSelectAddOptions[];
  onAdd: (teacher: CohortStaffAssignment) => void;
  onRemove: (teacher: CohortStaffAssignment) => void;
  removeFilter?: (teacher: CohortStaffAssignment) => boolean;
};

export function AssignCohortTeachers({
  className,
  includedUserIds,
  predefinedUsers,
  staffAssignments,
  engagementStaffMap,
  optionsInitialIndex,
  userTerm = "Teacher",
  staffAssignmentOptions,
  title = "Add Teachers",
  onAdd,
  onRemove,
  removeFilter,
}: Props) {
  const [error, setError] = useState<string | null>(null);

  const addUser = (
    user: AddUsersInput_UserFragment | null,
    assignmentType: CommonStaffAssignmentOptions | null
  ) => {
    if (!user) {
      setError("Please select a teacher.");
      return;
    }

    if (isCohortStaffAssignmentOptions(assignmentType)) {
      const selectionKey = `${user.id}_${assignmentType.cohortSubject}`;
      const keys = staffAssignments.map(
        (t) => `${t.user.id}_${t.cohortSubject}`
      );

      if (keys.includes(selectionKey)) {
        setError("This teacher has already been assigned to this subject!");
        return;
      }

      setError(null);
      onAdd({
        user,
        cohortSubject: assignmentType.cohortSubject,
        cohortAssignmentRole: assignmentType.cohortAssignmentRole,
      });
    }
  };

  return (
    <div className={clsx("flex flex-col gap-y-4", className)}>
      <AddUsersInput
        title={title}
        userTerm={userTerm}
        options={staffAssignmentOptions}
        optionsInitialIndex={optionsInitialIndex}
        userSearchAccountRoleAllowList={[AccountRole.TutorTeacher]}
        predefinedUsers={predefinedUsers?.length ? predefinedUsers : undefined}
        includedUserIds={includedUserIds?.length ? includedUserIds : undefined}
        onClickAdd={addUser}
        onSelect={() => setError(null)}
      />

      <FieldError msg={error} className="mt-[-10px]" />

      <CohortStaffAssignmentTable
        staffAssignments={staffAssignments}
        engagementStaffMap={engagementStaffMap}
        removeFilter={removeFilter}
        onRemove={onRemove}
      />
    </div>
  );
}
