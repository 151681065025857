import { gql } from "@apollo/client";
import {
  EngagementAssignmentRole,
  GetEngagementStaffMap_EngagementFragment,
} from "@generated/graphql";
import fromPairs from "lodash/fromPairs";

/**
 * Important Note:
 * We adhere to the Principle of Colocation here when it comes to GraphQL fragments.
 * https://www.apollographql.com/docs/react/data/fragments/#colocating-fragments
 * https://kentcdodds.com/blog/colocation#the-principle
 *
 * If you haven't yet discovered how great it is to colocate fragments you will
 * soon.
 *
 * But sometimes, colocating a fragment with a component might not work. Perhaps
 * you need to write a special data algorithm that could be re-used among many
 * components. Something like that that would normally be a candidate for our
 * collection of utility functions. But when that algorithm works with a certain
 * segment of data it's good to colocate that fragment with the function itself
 * and place them all in a dedicated spot: this withFragments directory.
 *
 * Utilize this file as a home for __staffing-related__ fragments, functions, and
 * types. If you want to work with another concern of our project (ex: users)
 * then start a new file.
 */

/**
 * EngagementStaffMap
 *
 * Use the following type, fragment, and function to generate a simple map
 * between users and their Engagement Assignment Roles. This is particularly
 * useful for Cohorts.
 *
 * General usage:
 * 1) Retrieve the Cohort's parent Engagement and take the Engagement's
 * staffAssignments property.
 * 2) Feed it into the function.
 * 3) Using the returned object you can then connect Engagement staff Assignment
 * Roles to your Cohort Staff.
 */
export type EngagementStaffMap = {
  [userId: string]: EngagementAssignmentRole;
};

getEngagementStaffMap.fragments = {
  engagement: gql`
    fragment GetEngagementStaffMap_Engagement on Engagement {
      staffAssignments {
        engagementAssignmentRole
        user {
          id
        }
      }
    }
  `,
};

/**
 * Provide a staffAssignments array with the engagementAssignmentRole and userId
 * and it will return a simple object with the userId as the key and the
 * engagementAssignmentRole as the value.
 *
 * @param staffAssignments
 * @returns EngagementStaffMap
 */
export function getEngagementStaffMap(
  staffAssignments: GetEngagementStaffMap_EngagementFragment["staffAssignments"]
): EngagementStaffMap {
  return fromPairs(
    staffAssignments.map((sa) => [sa.user.id, sa.engagementAssignmentRole])
  );
}
