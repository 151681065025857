import {
  AssignCohortStaffAssignment,
  AssignCommonStaffAssignment,
  AssignEngagementStaffAssignment,
  CohortStaffAssignmentOptions,
  CommonStaffAssignmentOptions,
  EngShiftAssignmentWithRole,
  EngagementStaffAssignmentOptions,
  TeacherAssignmentType,
} from "./types";

export function isEngagementStaffAssignmentOptions(
  assignment: CommonStaffAssignmentOptions | null
): assignment is EngagementStaffAssignmentOptions {
  return assignment?.type === TeacherAssignmentType.Engagement;
}

export function isCohortStaffAssignmentOptions(
  assignment: CommonStaffAssignmentOptions | null
): assignment is CohortStaffAssignmentOptions {
  return assignment?.type === TeacherAssignmentType.Cohort;
}

export function isAssignEngagementStaffTeacher(
  staffAssignment: AssignCommonStaffAssignment
): staffAssignment is AssignEngagementStaffAssignment {
  return (
    (staffAssignment as AssignEngagementStaffAssignment)
      ?.engagementAssignmentRole !== undefined &&
    (staffAssignment as EngShiftAssignmentWithRole)?.engagementShiftId ===
      undefined
  );
}

export function isAssignEngagementShiftTeacher(
  staffAssignment: AssignCommonStaffAssignment
): staffAssignment is EngShiftAssignmentWithRole {
  return (
    (staffAssignment as EngShiftAssignmentWithRole).engagementShiftId !==
    undefined
  );
}

export function isAssignCohortStaffTeacher(
  staffAssignment: AssignCommonStaffAssignment
): staffAssignment is AssignCohortStaffAssignment {
  return (
    (staffAssignment as AssignCohortStaffAssignment).cohortSubject !==
      undefined &&
    (staffAssignment as AssignCohortStaffAssignment).cohortAssignmentRole !==
      undefined
  );
}
