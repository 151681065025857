import {
  LocalizedWeekday,
  STANDARD_TIME_ZONES,
  WeekdaysCheck,
} from "@utils/dateTime";
import { getCohortSubjectText } from "@utils/labels";
import clsx from "clsx";
import { isNewEventId } from "components/cohorts/CohortScheduler/helpers";
import { DraftEvent } from "components/cohorts/CohortScheduler/types";
import {
  AssignmentIndicator,
  Icon,
  SelectMenu,
  SelectMenuOption,
  Tooltip,
} from "components/shared";
import noop from "lodash/noop";
import { TdHTMLAttributes, useEffect, useMemo, useState } from "react";

type Props = {
  editing: boolean;
  readOnly: boolean;
  toRemove: boolean;
  draftEvent: DraftEvent;
  localizedWeekdays: LocalizedWeekday[];
  onEdit: () => void;
  onDelete: () => void;
  onSelect: (id: DraftEvent["id"], teacherId: string | null) => void;
};

export function DraftEventsTableRow({
  editing,
  readOnly,
  toRemove,
  draftEvent,
  localizedWeekdays,
  onEdit,
  onDelete,
  onSelect,
}: Props) {
  const buildWeekdaysString = (weekdaysCheck: WeekdaysCheck) =>
    weekdaysCheck
      .map((checked, index) =>
        checked ? localizedWeekdays[index].shortWeekday : ""
      )
      .filter((s) => !!s)
      .join(", ");

  const [userSelectionName, setUserSelectionName] = useState<
    string | null | undefined
  >(draftEvent.teacherAssignedUser?.fullName || "None Selected");

  useEffect(() => {
    const usersList = draftEvent.teachersEventAssignments;
    if (!usersList?.length) return;
    if (!draftEvent.teacherAssignedId) {
      const subjectTeachers = draftEvent.teachersEventAssignments?.filter(
        ({ cohortSubject }) => cohortSubject === draftEvent.cohortSubject
      );
      if (subjectTeachers?.length === 1) {
        setUserSelectionName(subjectTeachers[0].user.fullName);
        onSelect(draftEvent.id, subjectTeachers[0].user.id);
      }
    } else {
      const teacherAssigned = usersList?.find(
        ({ user }) => user.id === draftEvent.teacherAssignedId
      );
      if (!teacherAssigned) {
        setUserSelectionName(null);
        onSelect(draftEvent.id, null);
      }
    }
  }, [draftEvent, onSelect, draftEvent.teacherAssignedId]);

  const TableData: React.FC<TdHTMLAttributes<{ className?: string }>> = ({
    children,
    className,
    ...tdProps
  }) => (
    <td
      className={clsx(
        "px-3 py-1 text-sm font-medium text-slate-900",
        className
      )}
      {...tdProps}
    >
      {children}
    </td>
  );

  const teachersOptions: SelectMenuOption<{ fullName: string }>[] =
    useMemo(() => {
      const usersList = draftEvent.teachersEventAssignments;
      const options = usersList?.length
        ? usersList
            .filter(
              (userElement) =>
                userElement.cohortSubject === draftEvent.cohortSubject
            )
            .map((userElement) => ({
              id: userElement.user.id,
              value: `${userElement.user.fullName}`,
              fullName: `${userElement.user.fullName}`,
            }))
        : [];

      options.unshift({
        id: "NONE_SELECTED",
        value: "None Selected",
        fullName: "None Selected",
      });

      return options;
    }, [draftEvent.cohortSubject, draftEvent.teachersEventAssignments]);

  const initialIndex = useMemo(() => {
    const index = teachersOptions.findIndex(
      (option) => option.fullName === userSelectionName
    );
    return index >= 0 ? index : 0;
  }, [teachersOptions, userSelectionName]);

  return (
    <tr
      className={clsx(
        "h-10",
        !editing && !toRemove && "odd:bg-slate-50 even:bg-slate-100",
        editing && "bg-orange-100",
        toRemove && "bg-red-100 line-through"
      )}
    >
      <TableData className="flex-1 w-fit ">
        <div className="flex gap-1 justify-start items-center">
          <AssignmentIndicator
            className="h-5 w-5"
            cohortAssignmentRoles={draftEvent.cohortAssignmentRoles}
            tooltipId={`assignment-indicator-${draftEvent.id}`}
            aria-hidden="true"
          />
          {isNewEventId(draftEvent.id) ? (
            <Tooltip content="New Event to be added">
              <Icon icon="add" size={4} color="text-emerald-600" />
            </Tooltip>
          ) : draftEvent.dirty && !draftEvent.toRemove ? (
            <Tooltip content="Event to be Updated">
              <Icon icon="save" size={5} />
            </Tooltip>
          ) : draftEvent.toRemove ? (
            <Tooltip content="Event to be Removed">
              <Icon icon="remove" size={5} color="text-rose-600" />
            </Tooltip>
          ) : null}
          {getCohortSubjectText(
            draftEvent.cohortSubject,
            "long",
            draftEvent.cohortSubSubject
          )}
        </div>
      </TableData>
      <TableData className="w-auto max-w-[130px]">
        {buildWeekdaysString(draftEvent.weekdaysCheck)}
      </TableData>
      <TableData className="whitespace-nowrap">
        {draftEvent.startTime} - {draftEvent.endTime}{" "}
        {STANDARD_TIME_ZONES[draftEvent.timeZone]?.shortTz ??
          draftEvent.timeZone}
      </TableData>
      <TableData>
        {teachersOptions && !readOnly ? (
          <SelectMenu
            listAlignment="right"
            className="min-w-[100px]"
            options={teachersOptions}
            onSelect={(option) => {
              if (!option || option.id === "NONE_SELECTED") {
                setUserSelectionName(null);
                onSelect(draftEvent.id, null);
                return;
              }

              const selectedUser = draftEvent?.teachersEventAssignments?.find(
                (t) => t.user.id === option.id
              );

              if (selectedUser) {
                setUserSelectionName(selectedUser.user.fullName);
                onSelect(draftEvent.id, selectedUser.user.id);
              }
            }}
            initialIndex={initialIndex}
          />
        ) : (
          (readOnly && draftEvent.teacherAssignedUser?.fullName) ||
          "None Selected"
        )}
      </TableData>
      {!readOnly && (
        <TableData className="flex-1 gap-1 justify-end items-center w-fit">
          <div className="h-fit flex gap-x-2 justify-end px-2">
            <Tooltip content="Edit Event">
              <Icon
                size={5}
                icon="edit"
                color="text-blue-600"
                className={clsx(
                  !editing && !toRemove && "cursor-pointer",
                  editing && "cursor-pointer animate-bounce",
                  toRemove && "opacity-0"
                )}
                onClick={toRemove ? noop : onEdit}
              />
            </Tooltip>
            {toRemove ? (
              <Tooltip content="Undo Event Deletion">
                <Icon
                  size={6}
                  icon="undo"
                  color="text-blue-600"
                  onClick={editing ? noop : onDelete}
                />
              </Tooltip>
            ) : (
              <Tooltip content="Delete Event">
                <Icon
                  size={5}
                  icon="remove"
                  color={clsx(
                    !editing && !toRemove && "cursor-pointer text-rose-600"
                  )}
                  onClick={editing ? noop : onDelete}
                />
              </Tooltip>
            )}
          </div>
        </TableData>
      )}
    </tr>
  );
}
