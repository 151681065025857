import { Time24HourLeadingZero, WeekdaysCheck } from "@utils/dateTime";

import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
} from "@generated/graphql";

export const NEW_EVENT_PREFIX = "new:";
export const DEFAULT_SUBJECT = CohortAssignmentSubject.Math;
export const DEFAULT_SUB_SUBJECT = CohortAssignmentSubSubject.General;
export const DEFAULT_WEEKDAYS_CHECK = Array(7).fill(false) as WeekdaysCheck;
export const DEFAULT_START_TIME: Time24HourLeadingZero = "08:00";
export const DEFAULT_END_TIME: Time24HourLeadingZero = "09:00";
