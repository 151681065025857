import { Time24HourLeadingZero } from "@utils/dateTime";

export const getTimePlusHour = (time: Time24HourLeadingZero) => {
  const [startHours, startMinutes] = time.split(":").map(Number);
  const newEndTime = new Date();
  newEndTime.setHours(startHours, startMinutes + 60);

  const endHours = newEndTime.getHours().toString().padStart(2, "0");
  const endMinutes = newEndTime.getMinutes().toString().padStart(2, "0");
  return `${endHours}:${endMinutes}`;
};

export const getUpcomingTime = (): string => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 15);
  const remainder = 5 - (now.getMinutes() % 5);
  if (remainder < 5) now.setMinutes(now.getMinutes() + remainder);
  const endHours = now.getHours().toString().padStart(2, "0");
  const endMinutes = now.getMinutes().toString().padStart(2, "0");
  return `${endHours}:${endMinutes}`;
};
