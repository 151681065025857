import {
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
} from "@generated/graphql";
import { PlusIcon, SaveIcon } from "@heroicons/react/solid";
import {
  IANAtzName,
  LocalizedWeekday,
  TIME_ZONE_OPTIONS,
  Time24HourLeadingZero,
  WeekdaysCheck,
} from "@utils/dateTime";
import { Button, SelectMenu, TimePicker } from "components/shared";
import { cohortSubjectOptions } from "components/shared/AttendanceGrades/constants";
import { getSubSubjectOptions } from "components/shared/AttendanceGrades/utils";
import { useEffect, useMemo, useState } from "react";
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
  DEFAULT_SUBJECT,
  DEFAULT_SUB_SUBJECT,
  DEFAULT_WEEKDAYS_CHECK,
  NEW_EVENT_PREFIX,
} from "../constants";
import { DraftEvent } from "../types";
import { getTimePlusHour, getUpcomingTime } from "../utils";
import { WeekdaysCheckboxes } from "./WeekdaysCheckboxes";

type Props = {
  initialDraftEvent?: Partial<DraftEvent>;
  updateMode: boolean;
  localizedWeekdays: LocalizedWeekday[];
  submitNewDraftEvent: (event: DraftEvent) => void;
  defaultTimeZone: IANAtzName;
};

export function DraftEventEditor({
  initialDraftEvent,
  updateMode,
  localizedWeekdays,
  submitNewDraftEvent,
  defaultTimeZone,
}: Props) {
  const initialCohortSubject =
    initialDraftEvent?.cohortSubject ?? DEFAULT_SUBJECT;
  const initialCohortSubSubject =
    initialDraftEvent?.cohortSubSubject ?? DEFAULT_SUB_SUBJECT;
  const initialStartTime = initialDraftEvent?.startTime ?? DEFAULT_START_TIME;
  const initialEndTime = initialDraftEvent?.endTime ?? DEFAULT_END_TIME;
  const initialTimeZone = initialDraftEvent?.timeZone ?? defaultTimeZone;
  const initialWeekdaysCheck =
    initialDraftEvent?.weekdaysCheck ?? DEFAULT_WEEKDAYS_CHECK;

  const initialTimeZoneIndex = useMemo(
    () =>
      TIME_ZONE_OPTIONS.findIndex(
        (option) => option.timeZone === initialTimeZone
      ),
    [initialTimeZone]
  );

  const initialSubjectIndex = useMemo(
    () =>
      cohortSubjectOptions.findIndex(
        (option) => option.subject === initialCohortSubject
      ),
    [initialCohortSubject]
  );

  const initialSubSubjectIndex = useMemo(() => {
    const newSubSubjectIndex = getSubSubjectOptions(
      initialCohortSubject
    ).findIndex((option) => option.subSubject === initialCohortSubSubject);
    if (newSubSubjectIndex === -1) return 0;
    return newSubSubjectIndex;
  }, [initialCohortSubSubject, initialCohortSubject]);

  const [draftCohortSubject, setDraftCohortSubject] =
    useState<CohortAssignmentSubject>(initialCohortSubject);
  const [draftCohortSubSubject, setDraftCohortSubSubject] =
    useState<CohortAssignmentSubSubject>(initialCohortSubSubject);
  const [draftStartTime, setDraftStartTime] =
    useState<Time24HourLeadingZero>(initialStartTime);
  const [draftEndTime, setDraftEndTime] =
    useState<Time24HourLeadingZero>(initialEndTime);
  const [draftTimeZone, setDraftTimeZone] =
    useState<IANAtzName>(initialTimeZone);
  const [draftWeekdaysCheck, setDraftWeekdaysCheck] =
    useState<WeekdaysCheck>(initialWeekdaysCheck);

  const subSubjectOptions = useMemo(
    () => getSubSubjectOptions(draftCohortSubject),
    [draftCohortSubject]
  );

  // Reset form when the initialDraftEvent changes.
  useEffect(() => {
    setDraftCohortSubject(initialCohortSubject);
    setDraftCohortSubSubject(initialCohortSubSubject);
    setDraftStartTime(initialStartTime);
    setDraftEndTime(initialEndTime);
    setDraftTimeZone(initialTimeZone);
    setDraftWeekdaysCheck(initialWeekdaysCheck);
  }, [
    initialCohortSubject,
    initialStartTime,
    initialEndTime,
    initialTimeZone,
    initialWeekdaysCheck,
    initialCohortSubSubject,
  ]);

  const updateWeekdayCheckbox = (index: number, checked: boolean) => {
    setDraftWeekdaysCheck((prev) => {
      if (index === 7) return Array(7).fill(true) as WeekdaysCheck;
      if (index === 8)
        return [false, true, true, true, true, true, false] as WeekdaysCheck;
      const newWeekdays: WeekdaysCheck = [...prev];
      newWeekdays[index] = checked;
      return newWeekdays;
    });
  };

  const updateStartTime = (time: string) => {
    setDraftStartTime(time);
    if (time > draftEndTime) setDraftEndTime(getTimePlusHour(time));
  };

  return (
    <div className="flex flex-row gap-2 flex-wrap">
      <label
        className="block text-sm font-medium text-gray-700 cursor-pointer w-full"
        onDoubleClick={() => updateWeekdayCheckbox(7, true)}
        onClick={() => updateWeekdayCheckbox(8, true)}
      >
        Schedule Builder
      </label>
      <div className="flex flex-col grow">
        <div className="grid grid-cols-16 gap-4 flex-wrap items-end mb-4">
          <SelectMenu
            labelText="Subject"
            options={cohortSubjectOptions}
            onSelect={(option) => {
              setDraftCohortSubject(option.subject);
              setDraftCohortSubSubject(CohortAssignmentSubSubject.General);
            }}
            initialIndex={initialSubjectIndex}
            listAlignment="left"
            className="col-span-8 sm:col-span-3"
          />

          <SelectMenu
            labelText="Sub-Subject"
            options={subSubjectOptions}
            onSelect={(option) =>
              setDraftCohortSubSubject(
                option.subSubject as CohortAssignmentSubSubject
              )
            }
            disabled={draftCohortSubject === CohortAssignmentSubject.General}
            initialIndex={initialSubSubjectIndex}
            listAlignment="left"
            className="col-span-8 sm:col-span-3"
          />

          <WeekdaysCheckboxes
            className="col-span-16 sm:col-span-10"
            updateWeekdayCheckbox={updateWeekdayCheckbox}
            draftWeekdaysCheck={draftWeekdaysCheck}
            localizedWeekdays={localizedWeekdays}
          />
        </div>

        <div className="grid grid-cols-12 gap-4 flex-wrap items-end">
          <TimePicker
            id="startTime"
            label="Start Time"
            time={draftStartTime}
            className="col-span-6 sm:col-span-2"
            onLabelClick={() => updateStartTime(getUpcomingTime())}
            onChange={(time) => updateStartTime(time)}
            step={300}
          />
          <TimePicker
            id="endTime"
            label="End Time"
            time={draftEndTime}
            className="col-span-6 sm:col-span-2"
            onChange={(time) => setDraftEndTime(time)}
            step={300}
          />

          <SelectMenu
            labelText="Time Zone"
            options={TIME_ZONE_OPTIONS}
            onSelect={(option) => setDraftTimeZone(option.timeZone)}
            initialIndex={initialTimeZoneIndex}
            listAlignment="right"
            className="col-span-6 sm:col-span-5"
          />
          <Button
            className="col-span-6 sm:col-span-3"
            type="button"
            theme="primary"
            onClick={() =>
              submitNewDraftEvent({
                id: initialDraftEvent?.id ?? `${NEW_EVENT_PREFIX}${new Date()}`,
                dirty: true,
                toRemove: false,
                teacherAssignedId: initialDraftEvent?.teacherAssignedId ?? null,
                cohortSubject: draftCohortSubject,
                cohortSubSubject: draftCohortSubSubject,
                startTime: draftStartTime,
                endTime: draftEndTime,
                timeZone: draftTimeZone,
                weekdaysCheck: draftWeekdaysCheck,
                cohortAssignmentRoles: [],
              })
            }
            disabled={
              draftWeekdaysCheck.every((weekday) => !weekday) ||
              draftStartTime >= draftEndTime
            }
          >
            {updateMode ? (
              <>
                <SaveIcon className="mr-1 h-5 w-5" aria-hidden="true" />
                Update
              </>
            ) : (
              <>
                <PlusIcon
                  className="mr-1 h-5 w-5 text-white"
                  aria-hidden="true"
                />
                Add
              </>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}
